/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-block-modal {
  min-width: 200px;
  max-width: 400px; }
  @media only screen and (min-width: 800px) {
    .blys-block-modal {
      width: 400px; } }
