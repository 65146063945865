/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-navigation {
  /* responsive */
  /* animations */
  /* default font */
  /* form background color and border */
  /* z-indexes */
  /* responsive */
  /* animations */
  /* default font */
  /* form background color and border */
  /* z-indexes */ }
  .blys-navigation__link {
    display: block;
    line-height: 25px;
    min-height: 25px;
    font-size: 14px;
    opacity: 1;
    font-weight: 400;
    cursor: pointer;
    color: inherit;
    transition-property: width, left, border-color;
    transition-duration: 0.3s, 0.3s, 0.3s;
    transition-timing-function: ease-out, ease-out, ease-out;
    position: relative; }
    .blys-navigation__link a {
      display: block;
      line-height: inherit;
      height: inherit;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      transition: inherit; }
    .blys-navigation__link div.badgeHolder {
      gap: 2px;
      top: -50%;
      right: -10px;
      z-index: 9999;
      display: flex;
      flex-wrap: wrap;
      position: absolute; }
    .blys-navigation__link div.badge {
      min-width: 25px;
      border-radius: 10px;
      padding: 0 4px;
      font-size: 14px;
      background: #d04437;
      color: #fff;
      text-align: center; }
      .blys-navigation__link div.badge.needsAttention {
        background: #f0d01e; }
      .blys-navigation__link div.badge.unpaid {
        background: #fac8b1; }
    .blys-navigation__link--with-icon {
      background-position: 0px 50%;
      background-size: 16px auto;
      background-repeat: no-repeat;
      padding-left: 30px; }
  .blys-navigation__separator {
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    opacity: 1;
    font-weight: 400;
    color: inherit; }
  @media only screen and (max-width: 799px) {
    .blys-navigation__wrapper {
      height: 60px;
      line-height: 60px;
      background: white;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      color: #fff;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 60000100; }
    .blys-navigation__links {
      height: 83%;
      overflow-x: hidden;
      overflow-y: scroll;
      touch-action: pan-y;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: scroll; }
      .blys-navigation__links-wrapper {
        visibility: hidden;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 120%;
        width: 265px;
        background: #FEFBFB;
        padding: 30px 0px 30px 30px;
        z-index: 60000104;
        transform: translateX(-265px);
        opacity: 0;
        transition: visibility 160ms ease, opacity 160ms ease, transform 160ms ease; }
        .blys-navigation__links-wrapper::-webkit-scrollbar {
          display: none; }
        .blys-navigation--open .blys-navigation__links-wrapper {
          visibility: visible;
          opacity: 1;
          transform: translateX(0px); }
    .blys-navigation__home {
      display: block;
      opacity: 1;
      height: 24px;
      width: 233px;
      background-position: 0% 50%;
      background-repeat: no-repeat;
      background-size: auto 24px;
      text-indent: 9999px;
      overflow: hidden;
      background-image: url("./media/blys_logo.svg");
      margin-bottom: 16px;
      position: absolute;
      top: 18px;
      left: 18px; }
    .blys-navigation__link {
      width: 100%;
      margin-bottom: 16px;
      color: #44444499;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 1px; }
      .blys-navigation__link:hover, .blys-navigation__link--selected {
        color: #444444ff;
        opacity: 1; }
      .blys-navigation__link div.badgeHolder {
        left: calc(100% - 80px); }
      .blys-navigation__link:last-chid {
        margin-bottom: 0px; }
    .blys-navigation__sidebar {
      margin-left: 16px; }
    .blys-navigation__submenu {
      padding-top: 16px;
      padding-left: 16px; }
    .blys-navigation__toggle {
      position: absolute;
      top: 15px;
      right: 20px;
      width: 20px;
      height: 28px;
      cursor: pointer;
      background-image: url("./media/menu.svg");
      background-position: 50% 50%;
      background-size: auto 100%;
      z-index: 60000101; }
    .blys-navigation__title {
      position: absolute;
      top: 0px;
      left: 64px;
      width: calc(100% - (16px * 8));
      height: 60px;
      line-height: 60px;
      text-align: center;
      z-index: 60000102;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 600; }
    .blys-navigation__shadow {
      touch-action: none;
      visibility: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 120%;
      background: rgba(40, 40, 40, 0.5);
      z-index: 60000103;
      opacity: 0;
      transition: visibility 160ms ease, opacity 160ms ease; }
      .blys-navigation--open .blys-navigation__shadow {
        visibility: visible;
        opacity: 1; } }
  @media only screen and (min-width: 800px) {
    .blys-navigation {
      min-width: 320px;
      z-index: 60000104;
      position: relative;
      padding: 35px 44px;
      line-height: 25px; }
      .blys-navigation__wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 60000100;
        height: 95px;
        background-color: rgba(255, 255, 255, 0.95);
        color: #5b5b5b;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.22);
        transition: box-shadow 0.3s ease-in-out;
        transition-delay: 0; }
      .blys-navigation__top {
        display: block;
        z-index: 60000104;
        height: 25px;
        line-height: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-end;
        justify-content: flex-end; }
      .blys-navigation__top .blys-navigation__link, .blys-navigation__top .blys-navigation__separator {
        margin-right: 26px;
        color: #5b5b5b;
        font-size: 18px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        position: relative;
        letter-spacing: 0.6px; }
        .blys-navigation__top .blys-navigation__link:last-child, .blys-navigation__top .blys-navigation__separator:last-child {
          margin-right: 0px; }
      .blys-navigation__top .blys-navigation__link:after {
        transition: width .3s ease-out, left .3s ease-out, border-color .3s ease-out;
        position: absolute;
        display: block;
        bottom: -6px;
        left: 50%;
        width: 0;
        border-top: 2px solid #7073e4;
        content: '';
        padding-bottom: inherit; }
      .blys-navigation__top .blys-navigation__link:hover:after, .blys-navigation__top .blys-navigation__link--selected:after {
        width: 100%;
        left: 0;
        opacity: 1; }
      .blys-navigation__link .blys-navigation__submenu {
        width: 200px;
        letter-spacing: 0.3px;
        position: absolute;
        top: 25px;
        padding-top: 35px;
        right: -13px;
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: visibility 160ms ease, opacity 160ms ease; }
      .blys-navigation__link:hover .blys-navigation__submenu {
        visibility: visible;
        opacity: 1; }
      .blys-navigation__submenu .blys-navigation__link {
        display: block;
        width: 100%;
        white-space: nowrap;
        height: 41px;
        line-height: 41px;
        padding: 0px 16px;
        color: #000;
        font-size: 16px;
        background: #FFF;
        border-left: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3ff; }
        .blys-navigation__submenu .blys-navigation__link:last-child {
          border-bottom: 1px solid #e3e3ff; }
        .blys-navigation__submenu .blys-navigation__link:hover {
          color: #6366e4;
          background: #f5f5f5; }
          .blys-navigation__submenu .blys-navigation__link:hover a {
            color: #6366e4; }
        .blys-navigation__submenu .blys-navigation__link:after {
          display: none; }
      .blys-navigation__sidebar {
        display: none;
        visibility: hidden; }
      .blys-navigation__home {
        opacity: .9;
        position: absolute;
        height: 40px;
        width: 70px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: auto 100%;
        top: 27.5px;
        left: 31px;
        text-indent: 9999px;
        overflow: hidden;
        background-image: url("./media/blys_logo.svg");
        transition: background-image 160ms ease, opacity 160ms ease; }
        .blys-navigation__home:hover {
          opacity: 1; }
      .blys-navigation__title {
        display: none;
        visibility: none; }
      .blys-navigation__toggle {
        display: none;
        visibility: none; }
      .blys-navigation__shadow {
        display: none;
        visibility: none; } }
