/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-sidebar-logo {
  height: 64px;
  width: 100%;
  align-self: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("../../assets/media/blys_logo.svg"); }

.blys-sidebar {
  gap: 16px;
  display: flex;
  position: fixed;
  padding-top: 16px;
  flex-direction: column;
  border-right: 1px solid #dcdfe6; }

.blys-sidebar-content {
  height: 100vh;
  overflow-y: auto;
  padding: 20px 16px 150px 16px; }

.scroll-vertical::-webkit-scrollbar {
  width: 6px; }

.scroll-vertical::-webkit-scrollbar-track {
  visibility: hidden; }

.scroll-vertical::-webkit-scrollbar-thumb {
  background: #e0e2e7;
  border-radius: 3px; }

/* Handle on hover */
.scroll-vertical::-webkit-scrollbar-thumb:hover {
  cursor: pointer; }
