.mce-container {
  border: 0px !important; }

.tox-tinymce {
  border: 0px !important; }

.tox-editor-header {
  background-color: inherit !important;
  box-shadow: inherit !important;
  padding: 0 !important; }
