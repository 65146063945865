/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.centered-block {
  display: block; }
  @media only screen and (max-width: 799px) {
    .centered-block {
      width: 100%; } }
  .centered-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .centered-block-wrapper--with-padding {
      padding: 16px; }
