.audience-criteria h2 {
  font-weight: bold; }

.audience-criteria .filters {
  margin-top: 20px; }
  .audience-criteria .filters ul {
    list-style: disc;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px; }
    .audience-criteria .filters ul li {
      line-height: normal; }
      .audience-criteria .filters ul li span.condition {
        color: #999; }

.audience-criteria .note {
  margin-top: 20px;
  color: #999;
  line-height: normal; }
