/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.i-payments-methods-list {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec; }
  .i-payments-methods-list__method {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 16px;
    background: #fff;
    border-bottom: 1px solid #ececec;
    line-height: 24px; }
    .i-payments-methods-list__method:last-child {
      border-bottom: 0px solid #ececec; }
    .i-payments-methods-list__method:hover {
      background: rgba(228, 227, 242, 0.4);
      cursor: pointer; }
    .i-payments-methods-list__method--selected, .i-payments-methods-list__method--selected:hover {
      background: rgba(228, 227, 242, 0.8) !important; }
    .i-payments-methods-list__method-name {
      text-align: left;
      flex-grow: 1;
      white-space: nowrap;
      letter-spacing: 0.6px;
      font-weight: 600;
      text-transform: capitalize; }
    .i-payments-methods-list__method-desc, .i-payments-methods-list__method-action {
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 5; }
