.container {
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 16px; }

.business-detail-container {
  display: flex;
  flex-direction: row;
  margin-top: 14px; }

.business-detail-text {
  margin-left: 10px;
  flex: 1;
  text-align: right; }

.header {
  font-weight: bold; }
