.tech-log-wrapper {
  background-color: #eee;
  max-width: 100%;
  overflow: scroll; }

.attributes-wrapper {
  padding: 10px;
  border-bottom: 1px solid; }

.attributes-wrapper p {
  padding: 3px 0; }

.att-first-level p {
  padding-left: 5px; }

.att_nested {
  margin-top: 10px; }
