.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container input {
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    line-height: 18px;
    min-height: 56px;
    outline: none;
    resize: none;
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-variant: normal;
    font-weight: 400;
    border-radius: 2px;
    padding: 19px 15px;
    color: #333;
    background: #e4e4f2;
    border: 1px solid #e4e4f2;
    transition: background 0.16s ease, color 0.16s ease, border 0.16s ease; }

.react-datepicker__header {
  background-color: #e4e4f2;
  border-bottom-color: #fff; }

.react-datepicker__day-names {
  background: #fff;
  margin-top: 15px;
  color: #999; }

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  margin-top: 15px; }
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select select {
    padding: 10px;
    background: #fff;
    border-color: #fff; }

.react-datepicker__day--selected {
  background-color: rgba(146, 146, 200, 0.7); }
  .react-datepicker__day--selected:hover {
    background-color: #9292c8; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--today {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--today:hover {
    background-color: #fff; }

.custom-modalities-list {
  border-top: 1px solid #ccc;
  background-color: #fff; }
  .custom-modalities-list li {
    padding: 15px;
    cursor: pointer; }
    .custom-modalities-list li:hover {
      background-color: rgba(146, 146, 200, 0.78);
      color: #ffffff; }

.service-alias {
  width: calc(100% - 60px);
  word-wrap: break-word; }

.therapist-profile {
  position: relative; }

.therapist-badge {
  position: absolute;
  right: 40px;
  bottom: -25px;
  width: 50px;
  height: 50px; }

.user-account-type-details-table {
  width: 100%;
  margin-top: 50px;
  border: 1px solid #f0eeee; }

.account-type-table-row {
  border-top: 1px solid #f0eeee; }

.account-type-table-th {
  padding: 20px;
  font-size: 16px;
  font-weight: 600; }

.account-type-table-td {
  padding: 20px;
  vertical-align: top;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px; }

.account-type-list {
  list-style: disc;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 1.5px; }

.account-status-bg-color-table {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 6px; }

.user-account-type-cancel-btn-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; }

.user-account-type-cancel-btn {
  width: 200px;
  height: 55px;
  border: 0;
  background: #9292c8;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer; }
