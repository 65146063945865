/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.action-icons {
  position: absolute;
  right: 0;
  top: 8px;
  display: flex;
  gap: 7px; }
  .action-icons .call-sms-icon-account-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
  .action-icons .call-sms-icon-account-detail > img {
    width: 16px;
    height: 16px; }
