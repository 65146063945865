/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-sidebar {
  width: 200px; }
  @media only screen and (max-width: 799px) {
    .blys-sidebar {
      left: 0;
      width: 265px; } }
  .blys-sidebar__wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row; }
  @media only screen and (max-width: 799px) {
    .blys-sidebar__sidebar {
      display: none; } }
  @media only screen and (min-width: 800px) {
    .blys-sidebar__sidebar {
      display: block;
      width: 200px;
      color: #444; }
      .blys-sidebar__sidebar .blys-navigation__link {
        padding-left: 10px;
        margin-bottom: 16px;
        color: #424242;
        border-radius: 5px;
        letter-spacing: 1px;
        font-size: 16px;
        line-height: 30px; }
        .blys-sidebar__sidebar .blys-navigation__link:hover, .blys-sidebar__sidebar .blys-navigation__link--selected {
          background: rgba(223, 224, 239, 0.6); }
        .blys-sidebar__sidebar .blys-navigation__link:last-child {
          margin-bottom: 0px; }
      .blys-sidebar__sidebar .blys-navigation__separator {
        color: #424242;
        padding-left: 10px;
        margin-bottom: 16px;
        line-height: 30px; }
        .blys-sidebar__sidebar .blys-navigation__separator:last-child {
          margin-bottom: 0px; } }
  .blys-sidebar__content {
    position: relative; }
    @media only screen and (max-width: 799px) {
      .blys-sidebar__content {
        padding: 0px 16px;
        width: 100%; } }
    @media only screen and (min-width: 800px) {
      .blys-sidebar__content {
        width: calc(100% - 200px);
        margin-top: 24px;
        padding: 0 32px; } }
