/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
#pLink {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  display: block; }
