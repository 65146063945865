/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
@keyframes loading-dots {
  0% {
    transform: scale(1, 1); }
  100% {
    transform: scale(0.4, 0.4); } }

.form--with-text-align-center {
  text-align: center; }

.form--with-text-align-left {
  text-align: left; }

.form--with-text-align-right {
  text-align: right; }

.form__form {
  display: block;
  margin: 0px;
  padding: 0px; }

.form__loader {
  text-align: center;
  white-space: nowrap; }
  .form__loader--big {
    height: 56px;
    line-height: 56px; }
  .form__loader--small {
    height: 12px;
    line-height: 12px; }
  .form__loader__dot {
    display: inline-block;
    font-size: 0;
    background: #999;
    animation-name: loading-dots;
    animation-duration: 400ms;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
    .form__loader--big .form__loader__dot {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      border-radius: 50%; }
    .form__loader--small .form__loader__dot {
      width: 4px;
      height: 8px;
      margin-right: 3px; }
    .form__loader__dot:last-child {
      margin-right: 0px; }
    .form__loader__dot--1 {
      animation-delay: 0ms; }
    .form__loader__dot--2 {
      animation-delay: 200ms; }
    .form__loader__dot--3 {
      animation-delay: 400ms; }

.form__desc {
  display: block;
  font-size: 13px;
  line-height: 18px;
  color: #666;
  text-align: inherit;
  margin-bottom: 16px;
  font-weight: 400; }
  .form__desc strong {
    font-weight: 600; }
  .form__desc--with-align-center {
    text-align: center; }
  .form__desc--with-align-left {
    text-align: left; }
  .form__desc--with-align-right {
    text-align: right; }

.form__title {
  text-align: inherit;
  letter-spacing: 1px;
  font-weight: 400; }
  .form__title--kind-big {
    font-size: 27px;
    line-height: 30px;
    min-height: 30px;
    margin-bottom: 32px; }
  .form__title--kind-small {
    font-size: 18px;
    line-height: 26px;
    min-height: 26px; }
  .form__title--kind-tiny {
    font-size: 14px;
    line-height: 20px;
    min-height: 20px; }
  .form__title--with-padding-top {
    padding-top: 16px; }
  .form__title--with-align-center {
    text-align: center; }
  .form__title--with-align-left {
    text-align: left; }
  .form__title--with-align-right {
    text-align: right; }

.form__label {
  display: block;
  margin-bottom: 32px;
  text-align: inherit;
  position: relative; }
  .form__label:last-child {
    margin-bottom: 0px; }

.form__field-title {
  display: block;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 16px; }
  .form__field-title--with-tooltip {
    position: relative; }
  .form__field-title-tooltip {
    position: absolute;
    top: -7px;
    right: 0px;
    width: 24px;
    height: 24px; }
    .form__field-title-tooltip-button {
      width: 24px;
      height: 24px;
      background-image: url("./media/tooltip.png");
      background-size: 24px auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      cursor: help;
      opacity: 0.7;
      transition: opacity 160ms ease; }
      .form__field-title-tooltip-button:hover {
        opacity: 1; }
    .form__field-title-tooltip-popup {
      display: block;
      text-transform: none;
      color: #111;
      border: 1px solid #dca;
      background: #fffaf0;
      box-shadow: 5px 5px 8px #ccc;
      padding: 16px;
      z-index: 2000000000;
      opacity: 0;
      visibility: hidden;
      font-size: 14px;
      line-height: 20px;
      transition: opacity 160ms ease, visibility 160ms ease; }
      @media only screen and (min-width: 800px) {
        .form__field-title-tooltip-popup {
          position: absolute;
          top: 0px;
          right: 30px;
          min-width: 260px;
          max-height: 100vh;
          max-width: 100vw;
          border-radius: 4px;
          transform: translateY(-50%); } }
      @media only screen and (max-width: 799px) {
        .form__field-title-tooltip-popup {
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          width: 100%;
          height: 100%;
          z-index: 60000110;
          overflow-y: scroll;
          padding-bottom: 100px; } }
      .form__field-title-tooltip-popup h6 {
        font-weight: bold;
        line-height: 24px; }
    .form__field-title-tooltip-close {
      position: fixed;
      left: 32px;
      right: 32px;
      bottom: 32px;
      line-height: 50px;
      height: 50px;
      text-align: center;
      z-index: 60000111;
      font-size: 16px;
      border: 1px solid #9292c8;
      color: #fff;
      background: #9292c8;
      text-transform: uppercase;
      opacity: 0;
      visibility: hidden;
      transition: opacity 160ms ease, visibility 160ms ease, box-shadow 160ms ease; }
      .form__field-title-tooltip-close:hover {
        box-shadow: 0px 0px 5px 3px rgba(146, 146, 200, 0.2); }
      @media only screen and (max-width: 799px) {
        .form__field-title-tooltip-close {
          display: block; } }
      @media only screen and (min-width: 800px) {
        .form__field-title-tooltip-close {
          display: none; } }
    @media only screen and (max-width: 799px) {
      .form__field-title-tooltip--show .form__field-title-tooltip-popup {
        visibility: visible;
        opacity: 1;
        display: block; } }
    @media only screen and (max-width: 799px) {
      .form__field-title-tooltip--show .form__field-title-tooltip-close {
        visibility: visible;
        opacity: 1;
        display: block; } }
    @media only screen and (min-width: 800px) {
      .form__field-title-tooltip:hover .form__field-title-tooltip-popup {
        visibility: visible;
        opacity: 1;
        display: block; } }

.form__callout {
  text-align: left;
  padding: 16px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px; }
  .form__callout--danger {
    background-color: rgba(219, 55, 55, 0.15); }
  .form__callout--success {
    background-color: rgba(13, 128, 80, 0.15); }

.form__selector-wrapper {
  cursor: pointer;
  position: relative; }

.form__selector-wrapper-sm {
  cursor: pointer;
  position: relative;
  width: 200px;
  margin-right: 10px; }

.form__selector-button {
  position: absolute;
  right: 16px;
  top: 0px;
  height: 56px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: opacity 160ms ease; }
  .form__selector-button--arrow {
    background-image: url("./media/arrow.png");
    background-size: 33px 18px;
    width: 33px; }
  .form__selector-button--arrow-sm {
    background-image: url("./media/arrow.png");
    background-size: 24px 14px;
    width: 24px; }
  .form__selector-button--clock {
    background-image: url("./media/clock.png");
    background-size: 34px 40px;
    width: 34px; }

.form__selector-title {
  width: 100%;
  line-height: 56px;
  height: 56px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px 50px 0px 16px;
  text-align: left;
  background: #ebf8fa;
  border: 1px solid #ebf8fa;
  color: #65666a;
  font-size: 14px;
  letter-spacing: 1px;
  transition: border 160ms ease; }

.form__selector-wrapper:hover .form__selector-button {
  opacity: 1; }

.form__selector-wrapper:hover .form__selector-title {
  border: 1px solid rgba(146, 146, 200, 0.5); }

.form__selector-select {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 56px;
  width: 100%;
  opacity: 0.01;
  cursor: pointer;
  -webkit-appearance: none;
  webkit-appearance: none; }

.form__selector-sm {
  height: 40px; }

.form__selector-slh {
  line-height: 40px; }

.form__input-text {
  box-sizing: border-box;
  width: 100%;
  line-height: 18px;
  min-height: 56px;
  margin-bottom: 4px;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  webkit-appearance: none;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-variant: normal;
  font-weight: normal;
  border-radius: 2px;
  padding: 19px 16px;
  color: #333;
  background: #ebf8fa;
  border: 1px solid #ebf8fa;
  transition: background 160ms ease, color 160ms ease, border 160ms ease; }
  .form__input-text--with-icon {
    padding-right: 63px; }
  .form__input-text--enabled:hover {
    border: 1px solid rgba(146, 146, 200, 0.5); }
  .form__input-text--enabled:focus,
  .form__input-text-wrapper:hover .form__input-text--enabled {
    border: 1px solid #b9e5ec; }
  .form__input-text::placeholder {
    color: #bbb;
    font-weight: 300; }
  .form__input-text--highlighted {
    border: 1px solid rgba(251, 101, 101, 0.5);
    background: #f8e0e0; }
  .form__input-text--highlighted:hover {
    border: 1px solid rgba(251, 101, 101, 0.7); }
  .form__input-text--highlighted:focus {
    border: 1px solid #fb6565; }
  .form__input-text:disabled {
    cursor: not-allowed;
    color: #999;
    background: rgba(228, 228, 242, 0.5);
    border: 1px solid rgba(228, 228, 242, 0.5); }
  .form__input-text-wrapper {
    position: relative;
    cursor: pointer; }
  .form__input-text-icon {
    position: absolute;
    cursor: pointer;
    width: 31px;
    height: 56px;
    top: 0px;
    right: 16px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: opacity 160ms ease; }
    .form__input-text-icon--calendar {
      background-image: url("./media/calendar.png");
      background-size: 31px 33px; }
    .form__input-text-icon--loading {
      background-image: url("./media/loader.gif");
      background-size: 16px 11px; }
    .form__input-text-icon--copy {
      background-image: url("./media/copy.png");
      background-size: 31px 33px; }
    .form__input-text-icon--container {
      width: 48px;
      height: 55px;
      position: absolute;
      right: 1px;
      top: 1px;
      padding: 20px 10px;
      align-items: center;
      display: flex;
      justify-content: center; }
    .form__input-text-icon--password {
      width: 20px; }
  .form__input-text-wrapper:hover .form__input-text-icon {
    opacity: 1; }
  .form__input-text-suggestions {
    position: absolute;
    visibility: hidden;
    z-index: 1;
    opacity: 0;
    background: #fff;
    border: 1px solid rgba(146, 146, 200, 0.7);
    margin-top: -1px;
    width: 100%;
    transition: opacity 160ms ease, visibility 160ms ease; }
    .form__input-text-suggestions--visible {
      visibility: visible;
      opacity: 1; }
    .form__input-text-suggestions--hidden {
      visibility: hidden;
      opacity: 0; }
    .form__input-text-suggestions-suggestion {
      padding: 16px;
      background: #fff;
      transition: background 160ms ease;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .form__input-text-suggestions-suggestion:hover {
        background: #ebf8fa; }
  .form__input-text-count {
    float: right; }
    .form__input-text-count-invalid {
      color: #db3737; }

.form__misc {
  display: block;
  text-align: inherit;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.6px; }
  .form__misc,
  .form__misc a {
    color: rgba(100, 100, 100, 0.8) !important; }
  .form__misc a {
    cursor: pointer; }
  .form__misc--with-align-center {
    text-align: center; }
  .form__misc--with-align-left {
    text-align: left; }
  .form__misc--with-align-right {
    text-align: right; }

.form__button {
  display: inline-block;
  padding: 0px 16px;
  cursor: pointer;
  letter-spacing: 1px;
  border-radius: 1px;
  margin-right: 16px;
  text-align: center;
  transition: background-color 160ms ease, color 160ms ease, box-shadow 160ms ease, filter 160ms ease;
  height: 56px;
  line-height: 56px;
  font-size: 16px; }
  .form__button:last-child {
    margin-right: 0px; }
  .form__button--disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    pointer-events: none; }
  .form__button--default {
    border: 1px solid #9292c8;
    color: #fff;
    background: #9292c8;
    text-transform: uppercase; }
    .form__button--default:hover {
      box-shadow: 0px 0px 5px 3px rgba(146, 146, 200, 0.2); }
  .form__button--blue {
    font-weight: 400;
    background: #009adb;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    letter-spacing: 1px;
    border-radius: 4px; }
    .form__button--blue:hover {
      box-shadow: 0px 0px 5px 3px rgba(0, 154, 219, 0.2); }
  .form__button--small {
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    border: 1px solid transparent;
    color: #666;
    text-transform: uppercase; }
    .form__button--small:hover {
      color: #000; }
  .form__button--blue-small {
    height: 24px;
    line-height: 22px;
    font-size: 13px;
    border: 1px solid #9292c8;
    color: #fff;
    background: #9292c8;
    border-radius: 4px; }
    .form__button--blue-small:hover {
      box-shadow: 0px 0px 5px 3px rgba(146, 146, 200, 0.2); }
  .form__button--secondary {
    background-color: #7e7e7e;
    color: #fff;
    text-transform: uppercase; }
    .form__button--secondary:hover {
      box-shadow: 0px 0px 5px 3px rgba(126, 126, 126, 0.2); }
  .form__button--danger {
    background-color: #ff4d4d;
    color: #fff;
    text-transform: uppercase; }
    .form__button--danger:hover {
      box-shadow: 0px 0px 5px 3px rgba(255, 77, 77, 0.2); }

.form__calendar-wrapper {
  min-width: 260px;
  border: 1px solid #ebf8fa;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px #666; }

.form__calendar-days {
  padding: 16px; }

.form__calendar-title {
  line-height: 50px;
  position: relative;
  background: #ebf8fa;
  padding: 0px 16px;
  vertical-align: top; }
  .form__calendar-title-month {
    text-align: center;
    padding: 0px 32px;
    line-height: inherit;
    height: inherit;
    font-size: 14px;
    letter-spacing: 1px;
    color: #333;
    vertical-align: top; }
  .form__calendar-title-button {
    position: absolute;
    top: 0px;
    line-height: inherit; }
    .form__calendar-title-button--next {
      right: 16px; }
    .form__calendar-title-button--prev {
      left: 16px; }
    .form__calendar-title-button--available {
      cursor: pointer; }
    .form__calendar-title-button--disabled {
      color: #aaa; }

.form__calendar-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  line-height: 30px;
  margin-bottom: 5px; }
  .form__calendar-list:last-child {
    margin-bottom: 0px; }
  .form__calendar-list-item {
    display: inline-block;
    width: 30px;
    line-height: inherit;
    white-space: nowrap;
    text-align: center;
    border-radius: 50%;
    color: #222;
    font-size: 12px;
    transition: color 160ms ease, background 160ms ease; }
    .form__calendar-list-item--enabled:hover {
      background: #ebf8fa;
      cursor: pointer; }
    .form__calendar-list-item--current-day {
      border: 1px solid #ccc;
      color: #000; }
    .form__calendar-list-item--active {
      background: #ebf8fa;
      color: #000; }
      .form__calendar-list-item--active:hover {
        background: #ebf8fa; }
    .form__calendar-list-item--disabled {
      color: #999; }

.form__hosted-field {
  height: 56px;
  line-height: 56px; }
  .form__hosted-field-container {
    height: inherit;
    line-height: inherit;
    padding: 0px 16px;
    background: #ebf8fa;
    border: 1px solid #ebf8fa;
    transition: background 160ms ease, border 160ms ease; }
    .form__hosted-field-container--disabled {
      cursor: not-allowed;
      filter: grayscale(100%); }
  .form__hosted-field .braintree-hosted-fields-focused {
    border: 1px solid #9292c8; }
  .form__hosted-field .braintree-hosted-fields-invalid {
    border: 1px solid rgba(251, 101, 101, 0.5);
    background: #f8e0e0; }
  .form__hosted-field .braintree-hosted-fields-focused.focused-invalid {
    border: 1px solid #fb6565; }

.form__input-checkbox {
  display: flex;
  align-content: center; }
  .form__input-checkbox.disabled {
    opacity: 0.5;
    pointer-events: none; }
  .form__input-checkbox .label {
    align-self: center;
    margin-right: 5px; }
  .form__input-checkbox .switch {
    position: relative;
    display: inline-block;
    min-width: 50px;
    height: 24px; }
  .form__input-checkbox .switch input {
    display: none; }
  .form__input-checkbox .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .form__input-checkbox .slider:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
  .form__input-checkbox input:checked + .slider {
    background-color: rgba(146, 146, 200, 0.7); }
  .form__input-checkbox input:checked + .slider.new {
    background-color: rgba(208, 68, 55, 0.7); }
  .form__input-checkbox input:checked + .slider.confirmed {
    background-color: rgba(142, 176, 33, 0.7); }
  .form__input-checkbox input:checked + .slider.onway {
    background-color: rgba(247, 146, 50, 0.7); }
  .form__input-checkbox input:checked + .slider.completed {
    background-color: rgba(146, 146, 200, 0.8); }
  .form__input-checkbox input:checked + .slider.cancelled {
    background-color: rgba(146, 146, 200, 0.3); }
  .form__input-checkbox input:checked + .slider.needsAttention {
    background-color: rgba(240, 208, 30, 0.7); }
  .form__input-checkbox input:checked + .slider.onlyCorporate {
    background-color: #f4a742; }
  .form__input-checkbox input:checked + .slider.isUnpaid {
    background-color: #fac8b1; }
  .form__input-checkbox input:focus + .slider {
    box-shadow: 0 0 1px rgba(146, 146, 200, 0.7); }
  .form__input-checkbox input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px); }
  .form__input-checkbox .slider.round {
    border-radius: 34px; }
  .form__input-checkbox .slider.round:before {
    border-radius: 50%; }

.formButtonWrapper {
  position: relative; }
  .formButtonWrapper:hover .formButton_hoverText {
    display: block; }

.formButton_hoverText {
  line-height: 22px;
  font-size: 14px;
  z-index: 100;
  position: absolute;
  color: black;
  text-align: center;
  background-color: #ebf8fa;
  display: none; }

.form-input-select-v2-parent {
  position: relative; }

.account-status-bg-color {
  position: absolute;
  left: 5px;
  top: calc((100% - 12px) / 2);
  width: 12px;
  height: 12px;
  border-radius: 2px; }

.form-input-select-v2 {
  width: 126px;
  height: 27px;
  border: 1px solid #c5cbd6;
  border-radius: 4px;
  padding-left: 20px; }
  .form-input-select-v2 .normal {
    width: 126px;
    height: 27px;
    border: 1px solid #c5cbd6;
    border-radius: 4px;
    padding-left: 20px; }
  .form-input-select-v2 .info {
    width: 126px;
    height: 27px;
    background-color: #e4e3f2;
    color: #65666a;
    border: 2px solid #9292cb;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif; }
