/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60000101; }

.lightbox-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid green; }

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer; }

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.cross-image {
  max-width: 30px;
  max-height: 30px; }

.carousel-image {
  width: 100%;
  height: 95%;
  object-fit: contain; }
  @media only screen and (max-width: 799px) {
    .carousel-image {
      width: 80%; } }
