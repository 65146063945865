@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

.react-tel-input {
  .form-control {
    @include placeholder {
      color: #bbbbbb;
    }
    margin-left: 80px !important;
    padding-left: 10px !important;

    &:focus {
      border-color: #b9e5ec !important;
    }

    &:hover {
      border-color: #b9e5ec !important;
    }
  }

  .flag-dropdown {
    &:focus {
      border-color: #b9e5ec !important;
    }

    &:hover {
      border-color: #b9e5ec !important;
    }

    .selected-flag {
      width: 100% !important;
      background: #ebf8fa !important;
      display: flex;
      justify-content: center;

      &:hover {
        background: #ebf8fa !important;
      }

      &:active {
        background: #ebf8fa;
      }

      .flag {
        margin-right: 20px !important;

        .arrow {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #555;
          margin-left: 10px !important;
        }

        .arrow.up {
          border-top: none;
          border-bottom: 5px solid #555;
        }
      }
    }
  }

  .flag-dropdown.open {
    background: #ebf8fa !important;

    .selected-flag {
      &:hover {
        background: #ebf8fa !important;
      }

      &:active {
        background: #ebf8fa !important;
      }
    }
  }
}
