/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.display-linebreak {
  white-space: pre-line; }

.editStatusButton {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .editStatusButton .statusLabel {
    margin-left: 10px; }
  .editStatusButton .form__label {
    margin-bottom: 0; }

.assignRemoveTherapistButton .therapistImage {
  width: 100px;
  height: 100px;
  background-size: cover;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  margin-right: 10px;
  display: inline-block; }

.assignRemoveTherapistButton .nameAndImage {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.assignRemoveTherapistButton .therapistInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.assignRemoveTherapistButton .therapistBoxName {
  line-height: 21px;
  word-break: break-all; }

.assignRemoveTherapistButton .therapistBoxID {
  margin-top: 2px;
  line-height: 21px; }

.booking-info-card__wrapper {
  padding: 24px;
  display: flex;
  gap: 24px; }
  .booking-info-card__wrapper .display-sp-linebreak {
    display: block; }

.booking-info-card__wrapper_mobileView {
  display: flex;
  flex-direction: column; }

.booking-info-card__status {
  margin-bottom: 10px; }

.booking-info-card__info {
  margin-bottom: 16px;
  color: #65666a;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px; }
  .booking-info-card__info:last-child {
    margin-bottom: 0px; }
  .booking-info-card__info .therapistInfo {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    align-items: flex-end;
    flex-wrap: wrap; }
    @media screen and (max-width: 600px) {
      .booking-info-card__info .therapistInfo {
        flex-wrap: wrap; }
        .booking-info-card__info .therapistInfo .therapistItem {
          width: 115px; } }
    .booking-info-card__info .therapistInfo .therapistActions {
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 10px 0 10px;
      margin-top: 15px; }
    .booking-info-card__info .therapistInfo .therapistImage {
      width: 75px;
      height: 75px;
      background-size: cover;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      margin: 0 10px;
      display: inline-block; }

.booking-info-card__price {
  background: #fff;
  padding: 16px;
  margin-top: 24px; }
  .booking-info-card__price-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px; }
    .booking-info-card__price-row:last-child {
      margin-bottom: 0px; }
    .booking-info-card__price-row span {
      gap: 8px;
      display: flex;
      text-align: right;
      align-items: center;
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
      line-height: 21px; }
  .booking-info-card__price strong {
    text-transform: uppercase;
    color: #333;
    font-size: 15px;
    letter-spacing: 0.6px;
    line-height: 24px; }
