/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
@media only screen and (max-width: 799px) {
  .popup, .popup__root {
    width: 100%;
    height: 100%; } }

@media only screen and (min-width: 800px) {
  .popup__shadow {
    background: rgba(30, 30, 30, 0.8);
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 2100000100;
    touch-action: none; } }

@media only screen and (max-width: 799px) {
  .popup__shadow {
    display: none; } }

.popup__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px; }
  @media only screen and (max-width: 799px) {
    .popup__wrapper {
      position: fixed; } }
  @media only screen and (min-width: 800px) {
    .popup__wrapper {
      display: flex;
      justify-content: center;
      align-items: center; } }
