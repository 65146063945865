.container {
  text-align: center; }

.coming-soon {
  color: #100249;
  font-size: 2rem;
  font-family: 'calluna', sans-serif;
  font-weight: bold;
  letter-spacing: 5px;
  display: inline-block; }

.coming-soon span {
  display: inline-block;
  animation: wave 2s infinite; }

.coming-soon span:nth-child(1) {
  animation-delay: 0.1s; }

.coming-soon span:nth-child(2) {
  animation-delay: 0.2s; }

.coming-soon span:nth-child(3) {
  animation-delay: 0.3s; }

.coming-soon span:nth-child(4) {
  animation-delay: 0.4s; }

.coming-soon span:nth-child(5) {
  animation-delay: 0.5s; }

.coming-soon span:nth-child(6) {
  animation-delay: 0.6s; }

.coming-soon span:nth-child(7) {
  animation-delay: 0.7s; }

.coming-soon span:nth-child(8) {
  animation-delay: 0.8s; }

.coming-soon span:nth-child(9) {
  animation-delay: 0.9s; }

.coming-soon span:nth-child(10) {
  animation-delay: 1s; }

.coming-soon span:nth-child(11) {
  animation-delay: 1.1s; }

@keyframes wave {
  0%,
  100% {
    opacity: 0.3; }
  50% {
    opacity: 1; } }
