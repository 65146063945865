.bg-primary {
  background-color: #9292c8; }

.text-primary {
  color: #9292c8; }

.border-primary {
  border-color: #9292c8; }

.bg-secondary {
  background-color: #e6f4ff; }

.text-secondary {
  color: #e6f4ff; }

.border-secondary {
  border-color: #e6f4ff; }

.bg-white {
  background-color: #fff; }

.text-white {
  color: #fff; }

.border-white {
  border-color: #fff; }

.bg-black {
  background-color: #000; }

.text-black {
  color: #000; }

.border-black {
  border-color: #000; }

.bg-grey {
  background-color: #fefbfb; }

.text-grey {
  color: #fefbfb; }

.border-grey {
  border-color: #fefbfb; }

.bg-danger {
  background-color: #e61a1a; }

.text-danger {
  color: #e61a1a; }

.border-danger {
  border-color: #e61a1a; }

.bg-muted {
  background-color: #7d7d7d; }

.text-muted {
  color: #7d7d7d; }

.border-muted {
  border-color: #7d7d7d; }

.text-2xs {
  font-size: 10px; }

.text-xs {
  font-size: 12px; }

.text-sm {
  font-size: 14px; }

.text-md {
  font-size: 16px; }

.text-lg {
  font-size: 18px; }

.text-xl {
  font-size: 20px; }

.text-2xl {
  font-size: 24px; }

.text-3xl {
  font-size: 28px; }

.text-4xl {
  font-size: 32px; }

.w-0 {
  width: 0px; }

.h-0 {
  height: 0px; }

.p-0 {
  padding: 0px; }

.px-0 {
  padding-left: 0px;
  padding-right: 0px; }

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.pl-0 {
  padding-left: 0px; }

.pr-0 {
  padding-right: 0px; }

.pt-0 {
  padding-top: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.m-0 {
  padding: 0px; }

.mx-0 {
  padding-left: 0px;
  padding-right: 0px; }

.my-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.ml-0 {
  padding-left: 0px; }

.mr-0 {
  padding-right: 0px; }

.mt-0 {
  padding-top: 0px; }

.mb-0 {
  padding-bottom: 0px; }

.gap-0 {
  gap: 0px; }

.gap-x-0 {
  column-gap: 0px; }

.gap-y-0 {
  row-gap: 0px; }

.radius-0 {
  border-radius: 0px; }

.border-0 {
  border-width: 0px; }

.w-1 {
  width: 4px; }

.h-1 {
  height: 4px; }

.p-1 {
  padding: 4px; }

.px-1 {
  padding-left: 4px;
  padding-right: 4px; }

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px; }

.pl-1 {
  padding-left: 4px; }

.pr-1 {
  padding-right: 4px; }

.pt-1 {
  padding-top: 4px; }

.pb-1 {
  padding-bottom: 4px; }

.m-1 {
  padding: 4px; }

.mx-1 {
  padding-left: 4px;
  padding-right: 4px; }

.my-1 {
  padding-top: 4px;
  padding-bottom: 4px; }

.ml-1 {
  padding-left: 4px; }

.mr-1 {
  padding-right: 4px; }

.mt-1 {
  padding-top: 4px; }

.mb-1 {
  padding-bottom: 4px; }

.gap-1 {
  gap: 4px; }

.gap-x-1 {
  column-gap: 4px; }

.gap-y-1 {
  row-gap: 4px; }

.radius-1 {
  border-radius: 4px; }

.border-1 {
  border-width: 4px; }

.w-2 {
  width: 8px; }

.h-2 {
  height: 8px; }

.p-2 {
  padding: 8px; }

.px-2 {
  padding-left: 8px;
  padding-right: 8px; }

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px; }

.pl-2 {
  padding-left: 8px; }

.pr-2 {
  padding-right: 8px; }

.pt-2 {
  padding-top: 8px; }

.pb-2 {
  padding-bottom: 8px; }

.m-2 {
  padding: 8px; }

.mx-2 {
  padding-left: 8px;
  padding-right: 8px; }

.my-2 {
  padding-top: 8px;
  padding-bottom: 8px; }

.ml-2 {
  padding-left: 8px; }

.mr-2 {
  padding-right: 8px; }

.mt-2 {
  padding-top: 8px; }

.mb-2 {
  padding-bottom: 8px; }

.gap-2 {
  gap: 8px; }

.gap-x-2 {
  column-gap: 8px; }

.gap-y-2 {
  row-gap: 8px; }

.radius-2 {
  border-radius: 8px; }

.border-2 {
  border-width: 8px; }

.w-3 {
  width: 12px; }

.h-3 {
  height: 12px; }

.p-3 {
  padding: 12px; }

.px-3 {
  padding-left: 12px;
  padding-right: 12px; }

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px; }

.pl-3 {
  padding-left: 12px; }

.pr-3 {
  padding-right: 12px; }

.pt-3 {
  padding-top: 12px; }

.pb-3 {
  padding-bottom: 12px; }

.m-3 {
  padding: 12px; }

.mx-3 {
  padding-left: 12px;
  padding-right: 12px; }

.my-3 {
  padding-top: 12px;
  padding-bottom: 12px; }

.ml-3 {
  padding-left: 12px; }

.mr-3 {
  padding-right: 12px; }

.mt-3 {
  padding-top: 12px; }

.mb-3 {
  padding-bottom: 12px; }

.gap-3 {
  gap: 12px; }

.gap-x-3 {
  column-gap: 12px; }

.gap-y-3 {
  row-gap: 12px; }

.radius-3 {
  border-radius: 12px; }

.border-3 {
  border-width: 12px; }

.w-4 {
  width: 16px; }

.h-4 {
  height: 16px; }

.p-4 {
  padding: 16px; }

.px-4 {
  padding-left: 16px;
  padding-right: 16px; }

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px; }

.pl-4 {
  padding-left: 16px; }

.pr-4 {
  padding-right: 16px; }

.pt-4 {
  padding-top: 16px; }

.pb-4 {
  padding-bottom: 16px; }

.m-4 {
  padding: 16px; }

.mx-4 {
  padding-left: 16px;
  padding-right: 16px; }

.my-4 {
  padding-top: 16px;
  padding-bottom: 16px; }

.ml-4 {
  padding-left: 16px; }

.mr-4 {
  padding-right: 16px; }

.mt-4 {
  padding-top: 16px; }

.mb-4 {
  padding-bottom: 16px; }

.gap-4 {
  gap: 16px; }

.gap-x-4 {
  column-gap: 16px; }

.gap-y-4 {
  row-gap: 16px; }

.radius-4 {
  border-radius: 16px; }

.border-4 {
  border-width: 16px; }

.w-5 {
  width: 20px; }

.h-5 {
  height: 20px; }

.p-5 {
  padding: 20px; }

.px-5 {
  padding-left: 20px;
  padding-right: 20px; }

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px; }

.pl-5 {
  padding-left: 20px; }

.pr-5 {
  padding-right: 20px; }

.pt-5 {
  padding-top: 20px; }

.pb-5 {
  padding-bottom: 20px; }

.m-5 {
  padding: 20px; }

.mx-5 {
  padding-left: 20px;
  padding-right: 20px; }

.my-5 {
  padding-top: 20px;
  padding-bottom: 20px; }

.ml-5 {
  padding-left: 20px; }

.mr-5 {
  padding-right: 20px; }

.mt-5 {
  padding-top: 20px; }

.mb-5 {
  padding-bottom: 20px; }

.gap-5 {
  gap: 20px; }

.gap-x-5 {
  column-gap: 20px; }

.gap-y-5 {
  row-gap: 20px; }

.radius-5 {
  border-radius: 20px; }

.border-5 {
  border-width: 20px; }

.w-6 {
  width: 24px; }

.h-6 {
  height: 24px; }

.p-6 {
  padding: 24px; }

.px-6 {
  padding-left: 24px;
  padding-right: 24px; }

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px; }

.pl-6 {
  padding-left: 24px; }

.pr-6 {
  padding-right: 24px; }

.pt-6 {
  padding-top: 24px; }

.pb-6 {
  padding-bottom: 24px; }

.m-6 {
  padding: 24px; }

.mx-6 {
  padding-left: 24px;
  padding-right: 24px; }

.my-6 {
  padding-top: 24px;
  padding-bottom: 24px; }

.ml-6 {
  padding-left: 24px; }

.mr-6 {
  padding-right: 24px; }

.mt-6 {
  padding-top: 24px; }

.mb-6 {
  padding-bottom: 24px; }

.gap-6 {
  gap: 24px; }

.gap-x-6 {
  column-gap: 24px; }

.gap-y-6 {
  row-gap: 24px; }

.radius-6 {
  border-radius: 24px; }

.border-6 {
  border-width: 24px; }

.w-7 {
  width: 28px; }

.h-7 {
  height: 28px; }

.p-7 {
  padding: 28px; }

.px-7 {
  padding-left: 28px;
  padding-right: 28px; }

.py-7 {
  padding-top: 28px;
  padding-bottom: 28px; }

.pl-7 {
  padding-left: 28px; }

.pr-7 {
  padding-right: 28px; }

.pt-7 {
  padding-top: 28px; }

.pb-7 {
  padding-bottom: 28px; }

.m-7 {
  padding: 28px; }

.mx-7 {
  padding-left: 28px;
  padding-right: 28px; }

.my-7 {
  padding-top: 28px;
  padding-bottom: 28px; }

.ml-7 {
  padding-left: 28px; }

.mr-7 {
  padding-right: 28px; }

.mt-7 {
  padding-top: 28px; }

.mb-7 {
  padding-bottom: 28px; }

.gap-7 {
  gap: 28px; }

.gap-x-7 {
  column-gap: 28px; }

.gap-y-7 {
  row-gap: 28px; }

.radius-7 {
  border-radius: 28px; }

.border-7 {
  border-width: 28px; }

.w-8 {
  width: 32px; }

.h-8 {
  height: 32px; }

.p-8 {
  padding: 32px; }

.px-8 {
  padding-left: 32px;
  padding-right: 32px; }

.py-8 {
  padding-top: 32px;
  padding-bottom: 32px; }

.pl-8 {
  padding-left: 32px; }

.pr-8 {
  padding-right: 32px; }

.pt-8 {
  padding-top: 32px; }

.pb-8 {
  padding-bottom: 32px; }

.m-8 {
  padding: 32px; }

.mx-8 {
  padding-left: 32px;
  padding-right: 32px; }

.my-8 {
  padding-top: 32px;
  padding-bottom: 32px; }

.ml-8 {
  padding-left: 32px; }

.mr-8 {
  padding-right: 32px; }

.mt-8 {
  padding-top: 32px; }

.mb-8 {
  padding-bottom: 32px; }

.gap-8 {
  gap: 32px; }

.gap-x-8 {
  column-gap: 32px; }

.gap-y-8 {
  row-gap: 32px; }

.radius-8 {
  border-radius: 32px; }

.border-8 {
  border-width: 32px; }

.w-9 {
  width: 36px; }

.h-9 {
  height: 36px; }

.p-9 {
  padding: 36px; }

.px-9 {
  padding-left: 36px;
  padding-right: 36px; }

.py-9 {
  padding-top: 36px;
  padding-bottom: 36px; }

.pl-9 {
  padding-left: 36px; }

.pr-9 {
  padding-right: 36px; }

.pt-9 {
  padding-top: 36px; }

.pb-9 {
  padding-bottom: 36px; }

.m-9 {
  padding: 36px; }

.mx-9 {
  padding-left: 36px;
  padding-right: 36px; }

.my-9 {
  padding-top: 36px;
  padding-bottom: 36px; }

.ml-9 {
  padding-left: 36px; }

.mr-9 {
  padding-right: 36px; }

.mt-9 {
  padding-top: 36px; }

.mb-9 {
  padding-bottom: 36px; }

.gap-9 {
  gap: 36px; }

.gap-x-9 {
  column-gap: 36px; }

.gap-y-9 {
  row-gap: 36px; }

.radius-9 {
  border-radius: 36px; }

.border-9 {
  border-width: 36px; }

.w-10 {
  width: 40px; }

.h-10 {
  height: 40px; }

.p-10 {
  padding: 40px; }

.px-10 {
  padding-left: 40px;
  padding-right: 40px; }

.py-10 {
  padding-top: 40px;
  padding-bottom: 40px; }

.pl-10 {
  padding-left: 40px; }

.pr-10 {
  padding-right: 40px; }

.pt-10 {
  padding-top: 40px; }

.pb-10 {
  padding-bottom: 40px; }

.m-10 {
  padding: 40px; }

.mx-10 {
  padding-left: 40px;
  padding-right: 40px; }

.my-10 {
  padding-top: 40px;
  padding-bottom: 40px; }

.ml-10 {
  padding-left: 40px; }

.mr-10 {
  padding-right: 40px; }

.mt-10 {
  padding-top: 40px; }

.mb-10 {
  padding-bottom: 40px; }

.gap-10 {
  gap: 40px; }

.gap-x-10 {
  column-gap: 40px; }

.gap-y-10 {
  row-gap: 40px; }

.radius-10 {
  border-radius: 40px; }

.border-10 {
  border-width: 40px; }

.w-11 {
  width: 44px; }

.h-11 {
  height: 44px; }

.p-11 {
  padding: 44px; }

.px-11 {
  padding-left: 44px;
  padding-right: 44px; }

.py-11 {
  padding-top: 44px;
  padding-bottom: 44px; }

.pl-11 {
  padding-left: 44px; }

.pr-11 {
  padding-right: 44px; }

.pt-11 {
  padding-top: 44px; }

.pb-11 {
  padding-bottom: 44px; }

.m-11 {
  padding: 44px; }

.mx-11 {
  padding-left: 44px;
  padding-right: 44px; }

.my-11 {
  padding-top: 44px;
  padding-bottom: 44px; }

.ml-11 {
  padding-left: 44px; }

.mr-11 {
  padding-right: 44px; }

.mt-11 {
  padding-top: 44px; }

.mb-11 {
  padding-bottom: 44px; }

.gap-11 {
  gap: 44px; }

.gap-x-11 {
  column-gap: 44px; }

.gap-y-11 {
  row-gap: 44px; }

.radius-11 {
  border-radius: 44px; }

.border-11 {
  border-width: 44px; }

.w-12 {
  width: 48px; }

.h-12 {
  height: 48px; }

.p-12 {
  padding: 48px; }

.px-12 {
  padding-left: 48px;
  padding-right: 48px; }

.py-12 {
  padding-top: 48px;
  padding-bottom: 48px; }

.pl-12 {
  padding-left: 48px; }

.pr-12 {
  padding-right: 48px; }

.pt-12 {
  padding-top: 48px; }

.pb-12 {
  padding-bottom: 48px; }

.m-12 {
  padding: 48px; }

.mx-12 {
  padding-left: 48px;
  padding-right: 48px; }

.my-12 {
  padding-top: 48px;
  padding-bottom: 48px; }

.ml-12 {
  padding-left: 48px; }

.mr-12 {
  padding-right: 48px; }

.mt-12 {
  padding-top: 48px; }

.mb-12 {
  padding-bottom: 48px; }

.gap-12 {
  gap: 48px; }

.gap-x-12 {
  column-gap: 48px; }

.gap-y-12 {
  row-gap: 48px; }

.radius-12 {
  border-radius: 48px; }

.border-12 {
  border-width: 48px; }

.w-13 {
  width: 52px; }

.h-13 {
  height: 52px; }

.p-13 {
  padding: 52px; }

.px-13 {
  padding-left: 52px;
  padding-right: 52px; }

.py-13 {
  padding-top: 52px;
  padding-bottom: 52px; }

.pl-13 {
  padding-left: 52px; }

.pr-13 {
  padding-right: 52px; }

.pt-13 {
  padding-top: 52px; }

.pb-13 {
  padding-bottom: 52px; }

.m-13 {
  padding: 52px; }

.mx-13 {
  padding-left: 52px;
  padding-right: 52px; }

.my-13 {
  padding-top: 52px;
  padding-bottom: 52px; }

.ml-13 {
  padding-left: 52px; }

.mr-13 {
  padding-right: 52px; }

.mt-13 {
  padding-top: 52px; }

.mb-13 {
  padding-bottom: 52px; }

.gap-13 {
  gap: 52px; }

.gap-x-13 {
  column-gap: 52px; }

.gap-y-13 {
  row-gap: 52px; }

.radius-13 {
  border-radius: 52px; }

.border-13 {
  border-width: 52px; }

.w-14 {
  width: 56px; }

.h-14 {
  height: 56px; }

.p-14 {
  padding: 56px; }

.px-14 {
  padding-left: 56px;
  padding-right: 56px; }

.py-14 {
  padding-top: 56px;
  padding-bottom: 56px; }

.pl-14 {
  padding-left: 56px; }

.pr-14 {
  padding-right: 56px; }

.pt-14 {
  padding-top: 56px; }

.pb-14 {
  padding-bottom: 56px; }

.m-14 {
  padding: 56px; }

.mx-14 {
  padding-left: 56px;
  padding-right: 56px; }

.my-14 {
  padding-top: 56px;
  padding-bottom: 56px; }

.ml-14 {
  padding-left: 56px; }

.mr-14 {
  padding-right: 56px; }

.mt-14 {
  padding-top: 56px; }

.mb-14 {
  padding-bottom: 56px; }

.gap-14 {
  gap: 56px; }

.gap-x-14 {
  column-gap: 56px; }

.gap-y-14 {
  row-gap: 56px; }

.radius-14 {
  border-radius: 56px; }

.border-14 {
  border-width: 56px; }

.w-15 {
  width: 60px; }

.h-15 {
  height: 60px; }

.p-15 {
  padding: 60px; }

.px-15 {
  padding-left: 60px;
  padding-right: 60px; }

.py-15 {
  padding-top: 60px;
  padding-bottom: 60px; }

.pl-15 {
  padding-left: 60px; }

.pr-15 {
  padding-right: 60px; }

.pt-15 {
  padding-top: 60px; }

.pb-15 {
  padding-bottom: 60px; }

.m-15 {
  padding: 60px; }

.mx-15 {
  padding-left: 60px;
  padding-right: 60px; }

.my-15 {
  padding-top: 60px;
  padding-bottom: 60px; }

.ml-15 {
  padding-left: 60px; }

.mr-15 {
  padding-right: 60px; }

.mt-15 {
  padding-top: 60px; }

.mb-15 {
  padding-bottom: 60px; }

.gap-15 {
  gap: 60px; }

.gap-x-15 {
  column-gap: 60px; }

.gap-y-15 {
  row-gap: 60px; }

.radius-15 {
  border-radius: 60px; }

.border-15 {
  border-width: 60px; }

.w-16 {
  width: 64px; }

.h-16 {
  height: 64px; }

.p-16 {
  padding: 64px; }

.px-16 {
  padding-left: 64px;
  padding-right: 64px; }

.py-16 {
  padding-top: 64px;
  padding-bottom: 64px; }

.pl-16 {
  padding-left: 64px; }

.pr-16 {
  padding-right: 64px; }

.pt-16 {
  padding-top: 64px; }

.pb-16 {
  padding-bottom: 64px; }

.m-16 {
  padding: 64px; }

.mx-16 {
  padding-left: 64px;
  padding-right: 64px; }

.my-16 {
  padding-top: 64px;
  padding-bottom: 64px; }

.ml-16 {
  padding-left: 64px; }

.mr-16 {
  padding-right: 64px; }

.mt-16 {
  padding-top: 64px; }

.mb-16 {
  padding-bottom: 64px; }

.gap-16 {
  gap: 64px; }

.gap-x-16 {
  column-gap: 64px; }

.gap-y-16 {
  row-gap: 64px; }

.radius-16 {
  border-radius: 64px; }

.border-16 {
  border-width: 64px; }

.w-17 {
  width: 68px; }

.h-17 {
  height: 68px; }

.p-17 {
  padding: 68px; }

.px-17 {
  padding-left: 68px;
  padding-right: 68px; }

.py-17 {
  padding-top: 68px;
  padding-bottom: 68px; }

.pl-17 {
  padding-left: 68px; }

.pr-17 {
  padding-right: 68px; }

.pt-17 {
  padding-top: 68px; }

.pb-17 {
  padding-bottom: 68px; }

.m-17 {
  padding: 68px; }

.mx-17 {
  padding-left: 68px;
  padding-right: 68px; }

.my-17 {
  padding-top: 68px;
  padding-bottom: 68px; }

.ml-17 {
  padding-left: 68px; }

.mr-17 {
  padding-right: 68px; }

.mt-17 {
  padding-top: 68px; }

.mb-17 {
  padding-bottom: 68px; }

.gap-17 {
  gap: 68px; }

.gap-x-17 {
  column-gap: 68px; }

.gap-y-17 {
  row-gap: 68px; }

.radius-17 {
  border-radius: 68px; }

.border-17 {
  border-width: 68px; }

.w-18 {
  width: 72px; }

.h-18 {
  height: 72px; }

.p-18 {
  padding: 72px; }

.px-18 {
  padding-left: 72px;
  padding-right: 72px; }

.py-18 {
  padding-top: 72px;
  padding-bottom: 72px; }

.pl-18 {
  padding-left: 72px; }

.pr-18 {
  padding-right: 72px; }

.pt-18 {
  padding-top: 72px; }

.pb-18 {
  padding-bottom: 72px; }

.m-18 {
  padding: 72px; }

.mx-18 {
  padding-left: 72px;
  padding-right: 72px; }

.my-18 {
  padding-top: 72px;
  padding-bottom: 72px; }

.ml-18 {
  padding-left: 72px; }

.mr-18 {
  padding-right: 72px; }

.mt-18 {
  padding-top: 72px; }

.mb-18 {
  padding-bottom: 72px; }

.gap-18 {
  gap: 72px; }

.gap-x-18 {
  column-gap: 72px; }

.gap-y-18 {
  row-gap: 72px; }

.radius-18 {
  border-radius: 72px; }

.border-18 {
  border-width: 72px; }

.w-19 {
  width: 76px; }

.h-19 {
  height: 76px; }

.p-19 {
  padding: 76px; }

.px-19 {
  padding-left: 76px;
  padding-right: 76px; }

.py-19 {
  padding-top: 76px;
  padding-bottom: 76px; }

.pl-19 {
  padding-left: 76px; }

.pr-19 {
  padding-right: 76px; }

.pt-19 {
  padding-top: 76px; }

.pb-19 {
  padding-bottom: 76px; }

.m-19 {
  padding: 76px; }

.mx-19 {
  padding-left: 76px;
  padding-right: 76px; }

.my-19 {
  padding-top: 76px;
  padding-bottom: 76px; }

.ml-19 {
  padding-left: 76px; }

.mr-19 {
  padding-right: 76px; }

.mt-19 {
  padding-top: 76px; }

.mb-19 {
  padding-bottom: 76px; }

.gap-19 {
  gap: 76px; }

.gap-x-19 {
  column-gap: 76px; }

.gap-y-19 {
  row-gap: 76px; }

.radius-19 {
  border-radius: 76px; }

.border-19 {
  border-width: 76px; }

.w-20 {
  width: 80px; }

.h-20 {
  height: 80px; }

.p-20 {
  padding: 80px; }

.px-20 {
  padding-left: 80px;
  padding-right: 80px; }

.py-20 {
  padding-top: 80px;
  padding-bottom: 80px; }

.pl-20 {
  padding-left: 80px; }

.pr-20 {
  padding-right: 80px; }

.pt-20 {
  padding-top: 80px; }

.pb-20 {
  padding-bottom: 80px; }

.m-20 {
  padding: 80px; }

.mx-20 {
  padding-left: 80px;
  padding-right: 80px; }

.my-20 {
  padding-top: 80px;
  padding-bottom: 80px; }

.ml-20 {
  padding-left: 80px; }

.mr-20 {
  padding-right: 80px; }

.mt-20 {
  padding-top: 80px; }

.mb-20 {
  padding-bottom: 80px; }

.gap-20 {
  gap: 80px; }

.gap-x-20 {
  column-gap: 80px; }

.gap-y-20 {
  row-gap: 80px; }

.radius-20 {
  border-radius: 80px; }

.border-20 {
  border-width: 80px; }

.flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.flex-col {
  flex-direction: column; }

.col-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.row-reverse {
  flex-direction: row-reverse; }

.justify-start {
  justify-content: start; }

.justify-baseline {
  justify-content: baseline; }

.justify-center {
  justify-content: center; }

.justify-end {
  justify-content: end; }

.justify-between {
  justify-content: space-between; }

.items-start {
  align-items: start; }

.items-self-center {
  align-self: center; }

.items-center {
  align-items: center; }

.items-end {
  align-items: end; }

.font-bold {
  font-weight: bold; }

.font-bolder {
  font-weight: bolder; }

.w-fit-content {
  width: fit-content; }

.w-full {
  width: 100%; }

.w-screen {
  width: 100vw; }

.cursor-pointer {
  cursor: pointer; }

.bg-none {
  background-color: transparent; }

.bg-cover {
  background-size: cover; }

.bg-center {
  background-position: center; }

.bg-no-repeat {
  background-repeat: no-repeat; }

.border-none {
  border: none; }

.border-solid {
  border-style: solid; }
