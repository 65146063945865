/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.summary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  margin-bottom: 32px; }
  @media only screen and (max-width: 799px) {
    .summary-container {
      align-items: flex-end;
      flex-direction: column;
      gap: 16px; } }

.summary-list {
  display: flex;
  flex-direction: column;
  width: 75%; }
  @media only screen and (max-width: 799px) {
    .summary-list {
      width: 60%; } }

.summary-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px; }

.export-button {
  height: 28px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center; }

.export-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 4px; }

.report-container {
  display: flex;
  gap: 8px;
  width: 300px;
  flex-direction: row;
  align-items: center;
  margin-left: 70px; }
  @media only screen and (max-width: 799px) {
    .report-container {
      width: 100%;
      flex: 1;
      margin-left: 0px; } }
  .report-container .select {
    flex: 1; }
