/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.scrollable {
  overflow: auto;
  width: 100%;
  border: 1px solid #ececec; }

.profileImageContainer {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  transform: translateX(20%);
  object-fit: cover; }

.scrollable-item {
  width: 1800px; }
  @media only screen and (max-width: 799px) {
    .scrollable-item {
      width: 100%; } }
