/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.paginator {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  width: 100%;
  position: relative; }
  .paginator__button {
    display: inline-block;
    cursor: pointer;
    color: #666;
    user-select: none;
    padding-right: 20px; }
    .paginator__button:last-child {
      padding-right: 0px; }
    .paginator__button:hover, .paginator__button--current {
      color: #333 !important; }
    .paginator__button--inactive, .paginator__button--inactive:hover {
      color: #CCC !important; }
  .paginator__prev {
    position: absolute;
    top: 0px;
    left: 0px; }
  .paginator__next {
    position: absolute;
    top: 0px;
    right: 0px; }
  .paginator__pages {
    text-align: center;
    padding: 0px 40px; }
