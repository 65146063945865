/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.upload_image {
  text-align: center; }
  .upload_image .form__label {
    background: #000;
    color: #ffffff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 115px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    border: 1px solid #9292c8;
    color: #fff;
    background: #9292c8;
    font-family: Arial; }
    .upload_image .form__label input {
      display: none; }
  .upload_image button {
    padding: 12px;
    margin-left: 10px;
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: inline-block; }

.image_holder {
  width: 300px;
  height: auto;
  min-height: 300px;
  margin: 20px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .image_holder img {
    width: 100%;
    display: block; }

.image_preview {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60000101;
  align-items: flex-end;
  flex-direction: column;
  display: flex; }
  .image_preview_header {
    margin: 25px; }
    .image_preview_header span {
      padding: 10px;
      background: #fff;
      border-radius: 5px;
      margin: 15px 10px;
      color: #ff0000;
      cursor: pointer; }
  .image_preview_body {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    max-width: 90%;
    transform: translate(-50%, -50%);
    border: 10px white solid;
    border-radius: 10px;
    background-color: white; }
    .image_preview_body img {
      width: 100%;
      height: auto;
      max-height: 90vh;
      display: block; }

.images_holder {
  margin-top: 10px; }
  .images_holder .photo,
  .images_holder .add_new_file {
    width: 90px;
    height: 90px;
    margin: 5px;
    display: inline-block; }
  .images_holder .photo {
    position: relative; }
    .images_holder .photo span {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      color: #ff0000;
      border-radius: 3px;
      padding: 3px;
      cursor: pointer; }
    .images_holder .photo img {
      cursor: -webkit-zoom-in; }
  .images_holder .add_new_file {
    display: inline-flex;
    align-items: center; }
    .images_holder .add_new_file .form__label {
      margin-top: 0; }
    .images_holder .add_new_file + button {
      padding: 12px;
      margin-left: 10px;
      background: #fff;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
      display: inline-block;
      height: 50px; }
  .images_holder img {
    width: 100%;
    height: 100%;
    display: block; }
  .images_holder .pdf-icon {
    width: 90px;
    margin: auto;
    cursor: pointer; }

.no_photo {
  background: #efeeee;
  border: 1px solid #ccc;
  color: #000000;
  width: 300px;
  height: 300px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .no_photo .therapistImage {
    width: 100%;
    height: 100%;
    background-size: cover; }

.preview_img {
  position: relative; }
  .preview_img span {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 25px;
    background: #ffffff;
    color: #9b0707;
    border-radius: 50%;
    cursor: pointer; }

.hiddenLink {
  display: none; }
