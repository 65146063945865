.receiver-image-wrapper {
  display: flex;
  justify-content: center; }
  .receiver-image-wrapper .image {
    height: 100px;
    width: 100px;
    background-color: #eee;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.call-duration {
  text-align: center;
  font-size: 16px;
  margin: 20px 0; }
  .call-duration strong {
    font-weight: 600; }

.dial-pad {
  margin-top: 20px;
  transition: all 0.3s ease 0s;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  background-color: #f6f6f6; }
  .dial-pad.active {
    max-height: 400px;
    padding: 10px; }
  .dial-pad .input {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #757575;
    margin-bottom: 10px;
    padding-bottom: 10px;
    gap: 10px; }
    .dial-pad .input .box {
      flex: 1;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      min-height: 40px;
      word-break: break-all; }
    .dial-pad .input .delete {
      padding: 0 11px;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #757575;
      cursor: pointer; }
      .dial-pad .input .delete:active:hover {
        background-color: #ccc; }
      .dial-pad .input .delete svg {
        width: 25px;
        height: 25px; }
  .dial-pad .numbers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 4px; }
    .dial-pad .numbers button {
      border: 1px solid #757575;
      border-radius: 30px;
      padding: 20px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      text-align: center; }
      .dial-pad .numbers button:hover {
        background-color: #ccc; }

.call-actions {
  justify-content: center;
  margin: 20px 0;
  gap: 8px; }
  .call-actions .item {
    height: 40px;
    width: 40px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .call-actions .item svg {
      height: 25px;
      width: 25px; }
    .call-actions .item:disabled {
      cursor: not-allowed; }

.return-to-call {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  background-color: #eee;
  cursor: pointer;
  position: sticky;
  top: 60px;
  z-index: 1; }
  @media only screen and (min-width: 800px) {
    .return-to-call {
      padding: 10px 41px;
      top: -1px; } }
  @media only screen and (max-width: 799px) {
    .return-to-call {
      margin-top: -32px;
      margin-bottom: 30px; } }
  .return-to-call .btn {
    display: flex;
    align-items: center;
    gap: 10px; }
    .return-to-call .btn img {
      height: 30px;
      width: 30px; }

.incoming {
  background-color: #ff7021;
  color: #fff; }
  .incoming img {
    filter: invert(1); }
