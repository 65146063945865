/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-notifications {
  position: fixed;
  z-index: 2200000000; }
  @media only screen and (min-width: 800px) {
    .blys-notifications {
      top: 111px;
      right: 16px;
      width: 400px; } }
  @media only screen and (max-width: 799px) {
    .blys-notifications {
      bottom: 16px;
      right: 0px;
      left: 0px;
      padding: 0px 16px; } }
  .blys-notifications__notification {
    padding: 16px;
    border-radius: 1px;
    text-align: left;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: opacity 160ms ease, visibility 160ms ease, border 160ms ease, background 160ms ease; }
    @media only screen and (max-width: 799px) {
      .blys-notifications__notification {
        width: 100%;
        display: block; } }
    @media only screen and (min-width: 800px) {
      .blys-notifications__notification {
        max-width: 400px;
        display: inline-block; } }
    .blys-notifications__notification--error {
      background: rgba(248, 224, 224, 0.9);
      border: 1px solid rgba(250, 162, 162, 0.8); }
      .blys-notifications__notification--error:hover {
        background: #f8e0e0;
        border: 1px solid #faa2a2; }
    .blys-notifications__notification--success {
      background-color: rgba(174, 218, 200, 0.9);
      border: 1px solid rgba(174, 218, 200, 0.8); }
      .blys-notifications__notification--success:hover {
        background-color: #aedac8;
        border: 1px solid #aedac8; }
    .blys-notifications__notification--visible {
      visibility: visible;
      opacity: 1; }
    .blys-notifications__notification--hidden {
      visibility: hidden;
      opacity: 0; }
    .blys-notifications__notification-wrapper {
      margin-bottom: 10px;
      display: block;
      text-align: right; }
      .blys-notifications__notification-wrapper:last-child {
        margin-bottom: 0px; }
