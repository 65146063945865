/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
a.filters-list,
.filters-list {
  width: 100%;
  border: 1px solid #ececec;
  display: flex; }
  a.filters-list__method,
  .filters-list__method {
    width: 100%;
    padding: 16px;
    background: #fff;
    border-bottom: 1px solid #ececec;
    line-height: 24px;
    cursor: pointer; }
    a.filters-list__method:hover,
    .filters-list__method:hover {
      background: rgba(228, 227, 242, 0.4);
      cursor: pointer; }
    a.filters-list__method--selected, a.filters-list__method--selected:hover,
    .filters-list__method--selected,
    .filters-list__method--selected:hover {
      background: rgba(228, 227, 242, 0.8) !important; }
    a.filters-list__method-name,
    .filters-list__method-name {
      color: black;
      text-align: center;
      letter-spacing: 0.6px;
      font-weight: 600;
      text-transform: capitalize; }

.therapistInfo {
  text-align: center;
  display: flex;
  justify-content: center; }
  .therapistInfo .therapistImage {
    width: 45px;
    height: 45px;
    background-size: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    margin: 0 2px;
    display: inline-block; }

.search-form {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row; }
  @media screen and (max-width: 799px) {
    .search-form {
      flex-direction: column; } }
  @media screen and (min-width: 800px) {
    .search-form .search-form-row {
      display: flex;
      flex-direction: row;
      align-items: center; } }
  @media screen and (max-width: 600px) {
    .search-form .search-form-row {
      flex-wrap: wrap; } }
  .search-form .form__input-text-wrapper {
    margin-right: 10px;
    min-height: 40px; }
    .search-form .form__input-text-wrapper:first-child, .search-form .form__input-text-wrapper:nth-child(2) {
      width: 100px; }
    .search-form .form__input-text-wrapper:not(:first-child) {
      flex-grow: 1; }
    @media screen and (max-width: 799px) {
      .search-form .form__input-text-wrapper {
        margin-right: 0;
        margin-bottom: 10px; } }
    .search-form .form__input-text-wrapper input {
      min-height: 40px;
      height: 40px;
      padding: 0 15px; }

.booking-list-action {
  width: auto !important;
  height: auto; }
  .booking-list-action:not(:first-child) {
    margin-top: 10px; }

.search-form .form__label {
  margin-top: 5px; }
  .search-form .form__label .form__field-title {
    padding-bottom: 10px; }
    @media screen and (max-width: 799px) {
      .search-form .form__label .form__field-title {
        padding-bottom: 20px; } }
  .search-form .form__label .form__input-text-wrapper {
    min-width: 225px; }
    @media screen and (max-width: 799px) {
      .search-form .form__label .form__input-text-wrapper {
        width: 95%;
        margin-right: 10px; } }
    .search-form .form__label .form__input-text-wrapper .form__input-text-icon {
      height: 39px; }
  @media screen and (max-width: 799px) {
    .search-form .form__label {
      flex-grow: 1; } }

.react-grid-HeaderCell .widget-HeaderCell__value {
  word-wrap: break-word;
  white-space: normal; }

.react-grid-Row div[title='Confirmed'] {
  color: #8eb021; }

.react-grid-Row div[title='Pending'] {
  color: #d04437; }

.react-grid-Row div[title='Declined'] {
  color: #d04437; }

.react-grid-Row div[title='Completed'] {
  color: #000; }

.react-grid-Row div[title='On Way'] {
  color: #f79232; }

.react-grid-Row div[title='Cancelled'] {
  color: #756e6e; }

.react-grid-Row div[title='Payment declined'] {
  color: #d04437; }

.react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value {
  height: 100%;
  display: flex;
  align-items: center; }
  .react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value > div {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    white-space: normal; }
    .react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value > div .therapistImage,
    .react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value > div .therapist-info {
      display: none; }
  .react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value .booking-notes ol {
    display: none; }
  .react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value .row-data + a {
    display: none; }

.bookingListContextMenu {
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  border: 1px solid rgba(61, 70, 77, 0.58); }
  .bookingListContextMenu .therapistInfo {
    flex-direction: column; }
  .bookingListContextMenu .therapistImage {
    display: block;
    height: 100px !important;
    width: 100px !important;
    margin-bottom: 15px !important; }
  .bookingListContextMenu .therapist-name {
    display: none; }
  .bookingListContextMenu .therapist-info {
    display: block; }
    .bookingListContextMenu .therapist-info div {
      margin-bottom: 10px; }
  .bookingListContextMenu .booking-notes {
    max-width: 300px; }
    .bookingListContextMenu .booking-notes > div {
      display: none; }
    .bookingListContextMenu .booking-notes > ol {
      margin-top: -15px !important; }
  .bookingListContextMenu .row-data {
    display: none; }
    .bookingListContextMenu .row-data + a {
      display: block; }

.missing-payment > div {
  background-color: rgba(250, 200, 177, 0.7); }

.missing-payment:hover > div {
  background-color: #fac8b1 !important; }

.call-sms-icon {
  padding-top: 10px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center; }
  .call-sms-icon img {
    width: 16px;
    height: 16px;
    cursor: pointer; }

.coupon-search {
  width: 100%;
  margin-left: 30px; }

.coupon-export-button {
  margin-top: 20px; }

@media screen and (max-width: 1024px) {
  .coupon-search-form {
    display: flex !important;
    flex-direction: column !important; }
  .coupon-search {
    margin-bottom: 30px;
    margin-left: 0px !important; }
  .coupon-export-button {
    margin-top: 0px; } }

@media screen and (max-width: 910px) {
  .coupon-export-button {
    margin-top: 15px; } }

@media screen and (max-width: 799px) {
  .coupon-search {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px !important; }
  .coupon-export-button {
    margin-top: 0px; } }

@media screen and (max-width: 490px) {
  .coupon-input-field-search {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 10px;
    margin-bottom: 20px; } }
