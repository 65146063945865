/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-content-block {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 16px; }
  .blys-content-block:last-child {
    margin-bottom: 0px; }
  .blys-content-block strong {
    font-weight: 600; }
  .blys-content-block--full {
    width: 100%; }
  .blys-content-block--fit {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
  .blys-content-block--with-text-align-center {
    text-align: center; }
  .blys-content-block--with-text-align-right {
    text-align: right; }
  .blys-content-block--with-text-align-left {
    text-align: left; }
  .blys-content-block--with-padding-top {
    padding-top: 16px; }
  .blys-content-block--with-content-padding {
    padding-left: 16px;
    padding-right: 16px; }
  @media only screen and (max-width: 799px) {
    .blys-content-block--with-navigation-overlap {
      margin-top: -92px; } }
  @media only screen and (min-width: 800px) {
    .blys-content-block--with-navigation-overlap {
      margin-top: -127px; } }
