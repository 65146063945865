.header {
  font-size: 27px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 30px;
  font-weight: 400;
  letter-spacing: 1px; }

.analyticsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2px; }

.analytics-title {
  font-size: 14px;
  font-weight: bold; }
