.call-sms-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  column-gap: 10px; }
  .call-sms-wrapper .item {
    cursor: pointer;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px;
    border-radius: 100%; }
    .call-sms-wrapper .item img {
      height: 30px;
      width: 30px; }
