/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.summary {
  max-width: 500px;
  margin: 0 auto; }

.message-summary {
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  min-height: 28px;
  margin: 0 auto;
  padding: 20px; }

.summary-modal {
  display: flex;
  flex-direction: row;
  background-color: #ebf8fa;
  min-height: 28px;
  margin: 0 auto;
  padding: 10px 20px; }

.message-summary-title {
  min-width: 200px;
  max-width: auto;
  color: gray;
  font-weight: 700px;
  font-size: 17px; }

.message-summary-body {
  background-color: #ebf8fa;
  font-size: 17px; }

.blys-login-modal {
  min-width: 200px;
  max-width: 400px; }
  @media only screen and (min-width: 800px) {
    .blys-login-modal {
      width: 400px; } }

.blys-sms-modal {
  min-width: 400px;
  min-height: 400px;
  background-color: #f9f9f9;
  padding: 40px 40px; }

.blys-summary-modal {
  min-width: 400px;
  min-height: 400px;
  background-color: #ebf8fa;
  padding: 40px 40px; }

.summary-modal-title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 25px; }
