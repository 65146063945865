.audio-player-container {
  width: 100%; }

.rhap_horizontal-reverse {
  flex-direction: column; }

@media screen and (min-width: 768px) {
  .audio-player-container {
    width: 75%; }
  .rhap_horizontal-reverse {
    flex-direction: row-reverse; } }

@media screen and (min-width: 1024px) {
  .audio-player-container {
    width: 60%; } }

@media screen and (min-width: 1440px) {
  .audio-player-container {
    width: 40%; } }

.rhap_container {
  border-radius: 25px;
  margin: 10px 0; }

.rhap_controls-section {
  gap: 5px; }

.rhap_volume-controls {
  justify-content: flex-start; }

.download-btn {
  border-radius: 5px;
  border-width: 0;
  padding: 5px 10px;
  background-color: #e5e5e5;
  cursor: pointer; }
