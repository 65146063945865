/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.nice-table__row-mobile-only {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #ececec; }
  .nice-table__row-mobile-only:last-child {
    margin-bottom: 0px; }
  .nice-table__row-mobile-only--title {
    display: none; }

@media only screen and (min-width: 800px) {
  .nice-table__row {
    background: #fff;
    line-height: 24px;
    border-top: 1px solid #ececec;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    transition: background 160ms ease; }
    .nice-table__row:last-child {
      border-bottom: 1px solid #ececec; }
    .nice-table__row:nth-child(2n) {
      background: #fbfbfb; }
    .nice-table__row--data:hover {
      background: whitesmoke; }
    .nice-table__row--title {
      font-weight: 600;
      letter-spacing: 0.6px;
      font-size: 16px; } }

@media only screen and (max-width: 799px) {
  .nice-table__row {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #ececec; }
    .nice-table__row:last-child {
      margin-bottom: 0px; }
    .nice-table__row--title {
      display: none; } }

.nice-table__col-mobile-only {
  min-height: 56px;
  width: 100% !important;
  background: #fff;
  border-bottom: 1px solid #ececec;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between; }
  .nice-table__col-mobile-only:last-child {
    border-bottom: 0px solid #ececec; }
  .nice-table__col-mobile-only:nth-child(2n) {
    background: #fbfbfb; }
  .nice-table__col-mobile-only-title {
    font-weight: 600;
    text-align: left;
    height: 24px;
    line-height: 24px;
    flex-grow: 1;
    white-space: nowrap; }
  .nice-table__col-mobile-only-content {
    padding-left: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 5;
    text-align: right; }

.nice-table__col {
  min-height: 56px; }
  @media only screen and (max-width: 799px) {
    .nice-table__col {
      width: 100% !important;
      background: #fff;
      border-bottom: 1px solid #ececec;
      padding: 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between; }
      .nice-table__col:last-child {
        border-bottom: 0px solid #ececec; }
      .nice-table__col:nth-child(2n) {
        background: #fbfbfb; }
      .nice-table__col-title {
        font-weight: 600;
        text-align: left;
        height: 24px;
        line-height: 24px;
        flex-grow: 1;
        white-space: nowrap; }
      .nice-table__col-content {
        padding-left: 16px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 5;
        text-align: right; } }
  @media only screen and (min-width: 800px) {
    .nice-table__col {
      display: inline-block;
      padding: 16px;
      vertical-align: top; }
      .nice-table__col--align-left {
        text-align: left; }
      .nice-table__col--align-right {
        text-align: right; }
      .nice-table__col--align-center {
        text-align: center; }
      .nice-table__col-title {
        display: none; }
      .nice-table__col-content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 24px; } }

.requestsent_status {
  color: #d04437; }

.confirmed_status {
  color: #8eb021; }

.onway_status {
  color: #f79232; }

.review-list .nice-table__col {
  padding: 15px 5px !important;
  height: 100%; }
