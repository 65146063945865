.tech-log-wrapper {
  background-color: #eee;
  max-width: 100%;
  overflow: scroll; }

.p4 {
  padding-left: 1px; }

.p10 {
  margin: 10px; }
