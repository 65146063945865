/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.content-grid {
  max-width: 100%;
  width: 100%; }
  @media only screen and (min-width: 800px) {
    .content-grid {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: flex-start;
      align-content: flex-start; } }
  .content-grid__item {
    max-width: 100%; }
    .content-grid__item--with-content-padding {
      padding: 0px 16px; }
    @media only screen and (max-width: 799px) {
      .content-grid__item {
        width: 100% !important;
        margin-bottom: 16px; }
        .content-grid__item:last-child {
          margin-bottom: 0px; } }
