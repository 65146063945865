/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.popup__window {
  position: relative;
  background: #FFF;
  padding: 32px;
  border-radius: 2px;
  font-family: "Open Sans", sans-serif;
  overflow-x: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; }
  @media only screen and (min-width: 800px) {
    .popup__window {
      max-width: 80vw;
      max-height: 85vh; } }
  @media only screen and (max-width: 799px) {
    .popup__window {
      width: 100%;
      height: 100%; } }
  @media only screen and (min-width: 800px) {
    .popup__window-close {
      display: none; } }
  @media only screen and (max-width: 799px) {
    .popup__window-close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 26px;
      height: 26px;
      background-image: url("./media/close.svg");
      background-size: 26px auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: .6; } }
  .popup__window-title {
    font-size: 20px;
    line-height: 20px;
    color: #666;
    margin: 0px 0px 15px 0px;
    font-weight: bold;
    letter-spacing: 1px; }
  .popup__window::-webkit-scrollbar {
    display: none; }
  .popup__window-content {
    font-size: 14px; }
