/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.abandoned-jobs-list {
  width: 100%;
  border: 1px solid #ececec;
  display: flex;
  flex-direction: column; }
  .abandoned-jobs-list .abandoned-job {
    gap: 16px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ececec; }
    .abandoned-jobs-list .abandoned-job:nth-child(odd) {
      background-color: #fff; }
    .abandoned-jobs-list .abandoned-job:nth-child(even) {
      background-color: #fbfbfb; }
    .abandoned-jobs-list .abandoned-job:last-child {
      border-bottom: none; }
    .abandoned-jobs-list .abandoned-job .job-date {
      align-self: center; }
      .abandoned-jobs-list .abandoned-job .job-date .date-box {
        display: flex;
        background-color: #e47777;
        color: #fff;
        width: 55px;
        height: 50px;
        justify-content: center;
        border-radius: 10px;
        align-items: center; }
    .abandoned-jobs-list .abandoned-job .job-info {
      flex: 1;
      cursor: pointer;
      color: #444; }
      .abandoned-jobs-list .abandoned-job .job-info .cancellation-text {
        display: flex;
        gap: 8px;
        flex-direction: column; }
      .abandoned-jobs-list .abandoned-job .job-info .cancellation-fee {
        font-weight: bold; }
    .abandoned-jobs-list .abandoned-job .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 8px; }
