/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.image-list-container {
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  padding: 5px;
  padding-bottom: 10px; }
  .image-list-container::-webkit-scrollbar {
    height: 5px; }
  .image-list-container::-webkit-scrollbar-thumb {
    background-color: #6c6a6a6e;
    border-radius: 5px; }

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px; }
