.messages-header {
  margin-bottom: 25px; }

.message {
  border: 1px solid #ececec;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  white-space: pre-line; }
  .message .info-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .message .info-action .message-content,
    .message .info-action .call-info {
      margin-bottom: 15px; }
  .message .mobile-info-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px; }
    .message .mobile-info-action .message-content,
    .message .mobile-info-action .call-info {
      margin-bottom: 15px; }
  .message .message-text {
    font-size: 16px; }
  .message .call-info div {
    margin-bottom: 5px; }
  .message .call-info .status span,
  .message .call-info .duration span {
    font-size: 14px;
    font-weight: 600; }

.days-select {
  margin: 0 5px; }

.filterWrapper {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  margin-top: '10px'; }

.filterSelector {
  flex: 1;
  max-width: 340px; }

.filterSelector .css-1rhbuit-multiValue {
  padding: 6px; }

.react-select-filter-channel {
  min-width: 220px;
  max-width: 400px;
  min-height: 40px;
  max-height: 40px;
  padding-top: 0;
  margin-right: 15px;
  margin-top: 0; }
