.card-wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .card-wrapper .card-content {
    gap: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
  .card-wrapper .card-title {
    text-align: center; }
    .card-wrapper .card-title__greet {
      font-size: 2rem; }
    .card-wrapper .card-title .bold {
      color: #301e79;
      font-weight: bold; }
  .card-wrapper .card-footer {
    text-align: center; }

.card-img {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative; }
  .card-img__img {
    width: 100%;
    filter: blur(0.8px); }
  .card-img__time {
    top: 75%;
    left: 50%;
    z-index: 2;
    color: #000000;
    font-size: 2.5rem;
    position: absolute;
    transform: translate(-50%, -50%); }
  .card-img .bw {
    filter: blur(0.7px) grayscale(100%) contrast(0) brightness(20%); }
