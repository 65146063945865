/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.filters-list {
  width: 100%;
  border: 1px solid #ececec;
  display: flex; }
  .filters-list__method {
    width: 100%;
    padding: 16px;
    background: #fff;
    border-bottom: 1px solid #ececec;
    line-height: 24px;
    cursor: pointer; }
    .filters-list__method:hover {
      background: rgba(228, 227, 242, 0.4);
      cursor: pointer; }
    .filters-list__method--selected, .filters-list__method--selected:hover {
      background: rgba(228, 227, 242, 0.8) !important; }
    .filters-list__method-name {
      text-align: center;
      letter-spacing: 0.6px;
      font-weight: 600;
      text-transform: capitalize; }

.therapistInfo {
  text-align: center;
  display: flex; }
  .therapistInfo .therapistImage {
    width: 45px;
    height: 45px;
    background-size: cover;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    margin: 0 2px;
    display: inline-block; }

.search-form {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row; }
  @media screen and (max-width: 799px) {
    .search-form {
      flex-direction: column; } }
  @media screen and (min-width: 800px) {
    .search-form .search-form-row {
      display: flex;
      flex-direction: row;
      align-items: center; } }
  @media screen and (max-width: 600px) {
    .search-form .search-form-row {
      flex-wrap: wrap; } }
  .search-form .form__input-text-wrapper {
    margin-right: 10px;
    min-height: 40px; }
    .search-form .form__input-text-wrapper:first-child, .search-form .form__input-text-wrapper:nth-child(2) {
      width: 100px; }
    .search-form .form__input-text-wrapper:not(:first-child) {
      flex-grow: 1; }
    @media screen and (max-width: 799px) {
      .search-form .form__input-text-wrapper {
        margin-right: 0;
        margin-bottom: 10px; } }
    .search-form .form__input-text-wrapper input {
      min-height: 40px;
      height: 40px;
      padding: 0 15px; }

.booking-list-action {
  width: auto !important;
  height: auto; }
  .booking-list-action:not(:first-child) {
    margin-top: 10px; }

.search-form .form__label {
  margin-top: 5px; }
  .search-form .form__label .form__field-title {
    padding-bottom: 10px; }
    @media screen and (max-width: 799px) {
      .search-form .form__label .form__field-title {
        padding-bottom: 20px; } }
  .search-form .form__label .form__input-text-wrapper {
    min-width: 225px; }
    @media screen and (max-width: 799px) {
      .search-form .form__label .form__input-text-wrapper {
        width: 95%;
        margin-right: 10px; } }
    .search-form .form__label .form__input-text-wrapper .form__input-text-icon {
      height: 39px; }
  @media screen and (max-width: 799px) {
    .search-form .form__label {
      flex-grow: 1; } }

.provider-search-form {
  margin-bottom: 20px; }
  .provider-search-form .provider-search-row {
    display: flex;
    flex-wrap: wrap; }
    .provider-search-form .provider-search-row * {
      margin-bottom: 10px; }
    .provider-search-form .provider-search-row .form__input-text-wrapper {
      margin-right: 10px;
      min-height: 40px; }
      .provider-search-form .provider-search-row .form__input-text-wrapper:first-child {
        width: 250px; }
      .provider-search-form .provider-search-row .form__input-text-wrapper:not(:first-child) {
        flex-grow: 1; }
      .provider-search-form .provider-search-row .form__input-text-wrapper input {
        min-height: 40px;
        height: 40px;
        padding: 0 15px; }
    .provider-search-form .provider-search-row .form__input-text-icon {
      height: 39px; }
    .provider-search-form .provider-search-row .form__selector-wrapper-sm {
      width: 300px; }
      @media screen and (max-width: 799px) {
        .provider-search-form .provider-search-row .form__selector-wrapper-sm {
          width: 40%;
          flex-grow: 1;
          max-width: 50%; } }
