/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
/* responsive */
/* animations */
/* default font */
/* form background color and border */
/* z-indexes */
.blys-login-modal {
  min-width: 200px;
  max-width: 400px; }
  @media only screen and (min-width: 800px) {
    .blys-login-modal {
      width: 400px; } }

.blys-login-modal.big-modal {
  min-width: 200px;
  max-width: 600px; }
  @media only screen and (min-width: 800px) {
    .blys-login-modal.big-modal {
      width: 600px; } }

.blys-login-modal.extra-big-modal {
  min-width: 200px;
  max-width: 900px; }
  @media only screen and (min-width: 800px) {
    .blys-login-modal.extra-big-modal {
      width: 800px; } }
