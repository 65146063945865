.messages-header {
  margin-bottom: 25px; }

.message {
  border: 1px solid #ececec;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  white-space: pre-line; }
  .message .message-content {
    margin-bottom: 15px; }
  .message .message-text {
    font-size: 16px; }

.days-select {
  margin: 0 5px; }
